import React from "react";
import {
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";

export const Registration = () => {
  return (
    <div>
      <img src="banner.jpg" alt="Banner" />
      <Typography variant="h1" c>
        Humanitarni Turnir
      </Typography>
      <Typography variant="h6">Ime ekipe:</Typography>
      <TextField variant="outlined" />

      <Typography variant="h6">Broj telefona:</Typography>
      <TextField variant="outlined" />

      <Typography variant="h6">Email adresa:</Typography>
      <TextField variant="outlined" />

      <div>
        <Typography variant="h6">Prijavljujem se za:</Typography>
        <FormControlLabel control={<Checkbox />} label="Option 1" />
        <FormControlLabel control={<Checkbox />} label="Option 2" />
        <FormControlLabel control={<Checkbox />} label="Option 3" />
        <FormControlLabel control={<Checkbox />} label="Option 4" />
        <FormControlLabel control={<Checkbox />} label="Option 5" />
        <FormControlLabel control={<Checkbox />} label="Option 6" />
      </div>
      <Button variant="outlined">Prijavi se</Button>
    </div>
  );
};
