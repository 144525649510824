import styled from "@emotion/styled";
import React from "react";
import { Box, Container, Typography, IconButton, Grid } from "@mui/material";
import footerLogo from "../assests/img/footerLogo.svg";

import {
  handleButtonCallUp,
  handleButtonEmail,
  handleButtonGoogleMaps,
} from "../constants";

import {
  SiFacebook,
  SiDiscord,
  SiInstagram,
  SiLinkedin,
  SiYoutube,
  SiTiktok,
} from "../../node_modules/react-icons/si/index.esm";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { Link } from "react-router-dom";

import phoneIcon from "../assests/icons/conntact/ic_baseline-phone-iphone.svg";
import emailIcon from "../assests/icons/conntact/ic_baseline-email.svg";
import locationIcon from "../assests/icons/conntact/ic_baseline-location-on.svg";

const tiktokLink = "https://www.tiktok.com/@regnumbelgrade";
const instagramLink = "https://www.instagram.com/regnumgaming";
const youtubeLink = "https://www.youtube.com/@regnumesports";
const discordLink = "https://discord.gg/VWFddTUcrp";
const facebookLink = "https://m.facebook.com/Regnumgamingbelgrade/";
const linkedinLink = "https://rs.linkedin.com/showcase/regnum-gaming-facility/";

const socialLinks = [
  { link: instagramLink, Icon: SiInstagram, key: "instagram" },
  { link: youtubeLink, Icon: SiYoutube, key: "youtube" },
  { link: discordLink, Icon: SiDiscord, key: "discord" },
  { link: linkedinLink, Icon: FaLinkedinIn, key: "linkedin" },
  { link: facebookLink, Icon: FaFacebookSquare, key: "facebook" },
  { link: tiktokLink, Icon: SiTiktok, key: "tiktok" },
];

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: "#AF85FF",
  "&:hover": {
    color: "#fff",
  },
}));

export const Contact = () => {
  return (
    <>
      <Container maxWidth="xl" sx={{ paddingTop: "1.5rem" }}>
        <Box sx={{ flexGrow: 1, padding: "1rem" }}>
          <Box sx={{ padding: 0, textAlign: { xs: "center", md: "left" } }}>
            <Typography
              gutterBottom
              sx={{
                paddingBottom: "1rem",
                typography: { xs: "mobilePageTitle", md: "pageTitle" },
              }}
            >
              Kontakt Informacije
            </Typography>
          </Box>
          <Grid
            container
            spacing={2}
            sx={{
              padding: "1rem 0 1rem 0",
              gap: { xs: "1rem", md: "none" },
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              flexWrap: "nowrap",
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  height: "300px",
                  width: "500px",
                  borderRadius: "16px",
                  boxShadow: " 0px 2px 14px rgba(175, 133, 255, 0.50); ",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ marginBottom: "2rem" }}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <IconButton onClick={handleButtonCallUp}>
                      <img src={phoneIcon} alt="phone-icon" />
                      <Typography ml={2} variant="footerFont" component="p">
                        +381 62 80 07 717
                      </Typography>
                    </IconButton>
                  </Box>
                  <Box display="flex" alignItems="center" mb={2}>
                    <IconButton onClick={handleButtonEmail}>
                      <img src={emailIcon} alt="email-icon" />
                      <Typography ml={2} variant="footerFont" component="p">
                        gaming@regnum.rs
                      </Typography>
                    </IconButton>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <IconButton onClick={handleButtonGoogleMaps}>
                      <img src={locationIcon} alt="location-icon" />
                      <Typography ml={2} variant="footerFont" component="p">
                        Tabanovačka 5
                      </Typography>
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  height: "300px",
                  width: "500px",
                  borderRadius: "16px",
                  boxShadow: "0px 2px 14px rgba(175, 133, 255, 0.50);",
                }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11326.99398551022!2d20.4737393!3d44.7859325!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a71d61813e5a7%3A0x31d67d4b503c41aa!2sREGNUM%20Gaming%20Facility!5e0!3m2!1sen!2srs!4v1721167764897!5m2!1sen!2srs"
                  width="100%"
                  height="100%"
                  style={{ border: 1, borderRadius: "16px" }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              paddingTop: "2rem",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img style={{ opacity: "0.8" }} src={footerLogo} alt="footerLogo" />
            <Box>
              {socialLinks.map(({ link, Icon, key }) => (
                <Link to={link} target="_blank" key={key}>
                  <CustomIconButton>
                    <Icon size={24} />
                  </CustomIconButton>
                </Link>
              ))}
            </Box>
            <Typography variant="footerFontCopyright">
              Copyright © 2024 | Regnum Gaming
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};
