import React from "react";
import { Box } from "@mui/material";

const BGMask = () => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "background.main",
      opacity: 0.7,
      zIndex: 1,
    }}
  ></Box>
);

const Content = ({ children }) => (
  <Box
    sx={{
      position: "relative",
      zIndex: 2,
    }}
  >
    {children}
  </Box>
);

export const ContainerWithImageBg = ({ image, children }) => (
  <Box
    sx={{
      position: "relative",
      backgroundImage: `url(${image})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }}
  >
    <BGMask />
    <Content>{children}</Content>
  </Box>
);
