import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Divider,
  Typography,
  styled,
} from "@mui/material";
// import skullIcon from "../assests/icons/skull.svg";
import logoMint from "../assests/img/logoMint.png";
import cardCover from "../assests/img/cardCover.jpg";
import { Link as RouterLink } from "react-router-dom";

export const CardGlassEffect = styled(Card)(
  `
background: #1B2123;
border-radius: 1.5rem;
// box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(10px);
-webkit-backdrop-filter: blur(10px);`
);

const CardHeaderStyledTitle = styled(Box)`
  position: absolute;
  top: -5rem;
  // right: 50%;
  transform: translate(0%, -50%);
  text-align: center;
  // padding: 8px;
  // width: 100px;
  height: 15px;
`;

const AboutUs = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        minHeight: "65vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              paddingTop: "2rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ typography: { xs: "mobilePageTitle", md: "pageTitle" } }}
            >
              Ko smo mi?
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-evenly",
                mt: "2rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: { md: "100%", xs: "auto" },
                  height: { md: "100%", xs: "auto" },
                  display: "flex",
                  borderRadius: "16px",
                }}
                justifyContent="center"
              >
                <CardGlassEffect
                  elevation={4}
                  sx={{
                    // backgroundColor: "#1B2123",
                    width: { xs: "auto", md: "100%" },
                    height: { xs: "auto", md: "100%" },
                    borderRadius: "16px",
                    // margin: "5rem",
                    overflow: "visible",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="160"
                    image={cardCover}
                    alt="card-cover-img"
                    sx={{ borderRadius: "10px 10px 0 0", opacity: 0.5 }}
                  />
                  <CardHeaderStyledTitle></CardHeaderStyledTitle>
                  <CardHeaderStyledTitle>
                    {" "}
                    <Box sx={{ padding: { md: "", xs: "1rem" } }}>
                      <img
                        src={logoMint}
                        alt="logo-mint"
                        style={{
                          width: "100%",
                          opacity: "0.6",
                          paddingTop: "7rem",
                        }}
                      />
                    </Box>
                  </CardHeaderStyledTitle>
                  <CardContent>
                    <Box marginTop="1rem">
                      <Divider color="#8565C2" />
                    </Box>
                    <Box
                      sx={{
                        marginTop: "1.8rem",
                        padding: { md: "0 4rem", xs: "auto" },
                      }}
                    >
                      <Typography variant="boxText" color="#fff">
                        Mi smo jedinstveni gaming centar na ovim prostorima,
                        posvećen pružanju vrhunskog iskustva ljubiteljima video
                        igrica.
                        <br />
                        <span style={{ color: "#AF85FF" }}>
                          Regnum Gaming Facility
                        </span>
                        &nbsp; je osmišljen kao mesto gde se stvara široka
                        gaming zajednica u kojoj svi igrači mogu da uživaju u
                        svojim omiljenim igricama i da se druže sa drugim
                        ljubiteljima gaming-a.
                      </Typography>
                    </Box>
                  </CardContent>
                  <Box>
                    <Button
                      sx={{ margin: "1rem 1rem" }}
                      variant="contained"
                      maxWidth="1.2rem"
                      component={RouterLink}
                      to="/about"
                    >
                      Saznajte Više
                    </Button>
                  </Box>
                </CardGlassEffect>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default AboutUs;
