import React, { useEffect, useState } from "react";
import { Card, Box, Typography, IconButton, Button } from "@mui/material";
import tournamentCover from "../../assests/img/tournamentCover.jpg";
import cs2cover from "../../assests/img/cs2.png";
import { IoClose } from "react-icons/io5";
import csCover from "../../assests/tournament/cs2cover.jpg";
import smallCover from "../../assests/tournament/smallCover.svg";

const tiktokLink =
  "https://docs.google.com/forms/d/e/1FAIpQLSec9WarCiAiQlEisicRn5R0eSxIJwuQOVCFOxC7HDbQEjQ5xQ/viewform";
const handleButton = () => {
  window.open(tiktokLink, "_blank");
};

function Tournament() {
  const [showPopup, setShowPopup] = useState(true);

  const handleClose = () => {
    setShowPopup(false);
  };

  if (!showPopup) {
    return null;
  }

  const imageCover = {
    backgroundImage: `url(${smallCover})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "inherit",
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    borderRadius: "20px",
  };
  const cs2coverImg = {
    backgroundImage: `url(${cs2cover})`,
    backgroundRepeat: "no-repeat",
    position: "absolute",
    width: "100%",
    height: "11rem",
    bottom: "0",
  };
  return (
    <div>
      <Box
        sx={{
          zIndex: "1",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div style={{ ...imageCover }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                // backgroundColor: "transparent",
                // background: "linear-gradient(#432f6ad4 0%, #21272a99 100%)",
                background: "linear-gradient(#432f6a38 0%, #0a0a0a99 100%)",
                width: { md: "1290px", xs: "330px" },
                height: { md: "720px", xs: "500px" },
                borderRadius: "16px",
                position: "relative",
                // opacity: "0.9",
              }}
            >
              <Box
                sx={{
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", pt: "20rem" }}
                >
                  <Typography
                    sx={{
                      flexGrow: 1,
                      color: "#00FFC8",
                      typography: {
                        xs: "advertisementContentMob",
                        md: "advertisementContent",
                      },
                    }}
                  >
                    09.12. - 17.12.
                  </Typography>
                  <Typography
                    sx={{
                      padding: "0",
                      flexGrow: 1,
                      typography: {
                        xs: "advertisementTitleMob",
                        md: "advertisementTitle",
                      },
                    }}
                  >
                    ZIMSKI TURNIR
                  </Typography>
                  <Typography
                    sx={{
                      padding: "0",
                      flexGrow: 1,
                      typography: {
                        xs: "advertisementContentMob",
                        md: "advertisementContent",
                      },
                    }}
                  >
                    PRIZE POOL: <span style={{ color: "#00FFC8" }}> 1100€</span>
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "20px",
                      right: "16px",
                      // flexDirection: "flex-end",
                      // display: "flex",
                      border: "1px solid #fff",
                      borderRadius: "6px",
                    }}
                  >
                    <IconButton
                      sx={{ padding: "1px" }}
                      aria-label="close"
                      onClick={handleClose}
                    >
                      <IoClose
                        sx={{
                          fontSize: "1rem",
                        }}
                        color="#fff"
                      />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      padding: "1rem",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        width: "200px",
                        height: "50px",
                      }}
                      onClick={handleButton}
                    >
                      <Typography variant="advertisementButton">
                        Prijavi Se
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                {/* <Box
                  sx={{
                    padding: "1rem",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      flexGrow: 1,
                      
                      typography: {
                        xs: "advertisementContentMob",
                        md: "advertisementContent",
                      },
                    }}
                  >
                    9.12. - 17.12.
                  </Typography>
                </Box> */}
                {/* <Box
                  sx={{
                    padding: "1rem",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      flexGrow: 1,
                      typography: {
                        xs: "advertisementContentMob",
                        md: "advertisementContent",
                      },
                    }}
                  >
                    Prize Pool:
                    <span style={{ color: "#00FFC8" }}> 1100€</span>
                  </Typography>
                </Box> */}
                {/* <Box
                  sx={{
                    paddingBottom: "1rem",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      border: "3px solid #00FFC8",
                      width: "200px",
                      height: "50px",
                    }}
                    onClick={handleButton}
                  >
                    <Typography variant="advertisementButton">
                      Prijavi Se
                    </Typography>
                  </Button>
                </Box> */}
              </Box>
            </Card>
          </Box>
        </div>
      </Box>
    </div>
  );
}

export default Tournament;
