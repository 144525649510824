import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
} from "@mui/material";

import { handleButtonCallUp } from "../constants";

import pc from "../assests/icons/birthday-icons/pc-icon.svg";
import sony from "../assests/icons/birthday-icons/sony-icon.svg";
import sandwich from "../assests/icons/birthday-icons/sandwich-icon.svg";
import drink from "../assests/icons/birthday-icons/drink-icon.svg";
import { CardGlassEffect } from "../pages/AboutUs";

const icons = [pc, sony, sandwich, drink];

const CardDescription = ({ descriptions }) => (
  <Box sx={{ mb: 1, mt: 1 }}>
    {icons.map((icon, index) => (
      <Box key={index} display="flex" alignItems="center" mt="1rem" mb="1rem">
        <img src={icon} alt={`icon-${index}`} style={{ marginRight: "8px" }} />
        <Typography sx={{ fontWeight: "500" }} variant="regnumCurrency">
          {descriptions[index]}
        </Typography>
      </Box>
    ))}
  </Box>
);

const mediumOfferBoxShadow = "1px solid #00FFC8";

const BirthdayCard = ({
  name,
  players,
  price,
  descriptions,
  buttonVariant,
  borderColor = false,
}) => {
  return (
    <Container maxWidth="sm">
      <CardGlassEffect
        sx={{
          width: { xs: "auto", md: "390px" },
          height: "auto",
          // backgroundColor: "#1B2023",
          alignContent: "center",
          borderRadius: "1.5rem",
          border: borderColor ? mediumOfferBoxShadow : "none",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontSize: "1.5rem" }}
            variant="regnumCurrency"
            gutterBottom
          >
            {name}
          </Typography>
          <Typography
            variant="regnumCurrency"
            sx={{ fontWeight: "normal", color: "#8565C2" }}
          >
            {players}
          </Typography>
          <CardDescription descriptions={descriptions} />
          <Typography
            sx={{ mb: 1, mt: 1, fontSize: "2rem", fontWeight: "700" }}
            variant="regnumCurrency"
          >
            {price}
          </Typography>
          <CardActions sx={{ mb: 1.5, mt: 1.5 }}>
            <Button
              onClick={handleButtonCallUp}
              sx={{ width: "15rem", color: "#FFFFFF" }}
              size="large"
              variant={buttonVariant}
            >
              Rezerviši
            </Button>
          </CardActions>
        </CardContent>
      </CardGlassEffect>
    </Container>
  );
};

export default BirthdayCard;
