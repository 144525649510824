import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Button } from "@mui/material";
import styled from "@emotion/styled";
import "../../styles/ui/phone-animation.css";

const CallUpButtonStyled = styled(Box)(() => ({
  position: "fixed",
  bottom: "1rem",
  right: "0.5rem",
  height: "2.5rem",
  width: "2.5rem",
  fontSize: "1.5rem",
  zIndex: "2",
  placeItems: "center",
  display: "grid",
  justifyContent: "center",
  border: "0.8px solid #00FFC8",
  borderRadius: "50%",
  cursor: "pointer",
  // "&:hover": {
  //   backgroundColor: "#FFFFFF",
  //   "& svg": {
  //     color: "#AF85FF",
  //   },
  // },
}));

const CallUpButton = () => {
  const handleButtonCallUp = () => {
    window.location.href = `tel:+381628007717`;
  };

  return (
    <CallUpButtonStyled>
      <Button onClick={handleButtonCallUp} style={{ display: "flex" }}>
        <PhoneIcon
          className="fa fa-phone faa-ring animated fa-5x"
          style={{ fontSize: "1.5rem", color: "#00FFC8" }}
        />
      </Button>
    </CallUpButtonStyled>
  );
};

export default CallUpButton;
