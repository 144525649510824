import { Container, Box, Typography } from "@mui/material";
import React from "react";
import { ContainerWithImageBg } from "../components/ContainerWithImageBg";
import eBar from "../../src/assests/img/eBar.jpg";
import { OfferCustomCard } from "../styles/appbar/index";
import BirthdayOfferContent from "./Components/OfferPage/BirthdayOfferContent";
import TeamBuilding from "./Components/OfferPage/TeamBuilding";
import BootCamp from "./Components/OfferPage/BootCamp";
import GamingArena from "./Components/OfferPage/GamingArena";
import PlayStation from "./Components/OfferPage/PlayStation";
import BoardGames from "./Components/OfferPage/BoardGames";

const OfferPage = () => {
  return (
    <Box>
      <Container
        maxWidth="xl"
        sx={{
          paddingTop: "5rem",
          display: "flex",
          flexDirection: "column",
          paddingBottom: "3rem",
        }}
      >
        <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
          <Typography
            sx={{
              typography: {
                xs: "mobilePageTitle",
                md: "pageTitle",
              },
            }}
          >
            Šta nudimo?
          </Typography>
        </Box>
        <Box sx={{ pt: "2rem" }}>
          {/* <ContainerWithImageBg image={eBar}> */}
          <Box
            sx={{
              padding: { md: "3rem 0", xs: "0" },
              display: { md: "flex", xs: "block" },
              justifyContent: { md: "space-between" },
            }}
          >
            <Box sx={{ paddingBottom: "3rem" }}>
              <OfferCustomCard elevation={6}>
                <GamingArena />
              </OfferCustomCard>
            </Box>
            <Box sx={{ paddingBottom: "3rem" }}>
              <OfferCustomCard elevation={6}>
                {" "}
                <BootCamp />
              </OfferCustomCard>
            </Box>
            <Box sx={{ paddingBottom: "3rem" }}>
              {" "}
              <OfferCustomCard elevation={0}>
                {" "}
                <PlayStation />
              </OfferCustomCard>
            </Box>
          </Box>

          {/* </ContainerWithImageBg> */}
        </Box>
        <Box sx={{ pt: "2rem" }}>
          {/* <ContainerWithImageBg image={eBar}> */}
          <Box
            sx={{
              padding: { md: "3rem 0", xs: "0" },
              display: { md: "flex", xs: "block" },
              justifyContent: { md: "space-between" },
            }}
          >
            <Box sx={{ paddingBottom: "3rem" }}>
              <OfferCustomCard elevation={6}>
                <BirthdayOfferContent />
              </OfferCustomCard>
            </Box>
            <Box sx={{ paddingBottom: "3rem" }}>
              <OfferCustomCard elevation={6}>
                <TeamBuilding />
              </OfferCustomCard>
            </Box>
            <Box sx={{ paddingBottom: "3rem" }}>
              <OfferCustomCard elevation={0}>
                <BoardGames />
              </OfferCustomCard>
            </Box>
          </Box>

          {/* </ContainerWithImageBg> */}
        </Box>
      </Container>
    </Box>
  );
};

export default OfferPage;
