import React from "react";
import { Card, Box, Typography, useTheme } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import drinkIcon from "../../assests/img/drinkIcon.svg";
import styled from "@emotion/styled";
import theme from "../../styles/theme";

const BigScreenStyledBox = styled(Box)`
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, -50%);
  text-align: center;
  padding: 8px;
  width: 100px;
  height: 15px;
  background: #00ffc8;
  // box-shadow: 0px 0px 20px 10px #00ffc8;
  border: 1px solid white;
  border-radius: 10px;
  z-index: 1; /* Set a higher z-index to make it appear above sibling elements */
  /* Optionally, you can add a box-shadow here */
`;

const CardHeaderStyledTitle = styled(Box)`
  position: absolute;
  top: -30px;
  right: 20%;
  transform: translate(50%, -50%);
  text-align: center;
  padding: 8px;
  width: 100px;
  height: 15px;
`;

const CardIcon = ({ iconType }) => {
  const renderIcon = () => {
    if (iconType === "pc") {
      return (
        <AccessTimeIcon
          sx={{
            color: theme.palette.white.main,
            fontSize: 30,
            paddingTop: "2.4rem",
          }}
        />
      );
    } else if (iconType === "ps") {
      return (
        <SportsEsportsIcon
          sx={{
            color: theme.palette.white.main,
            fontSize: 30,
            paddingTop: "2.4rem",
          }}
        />
      );
    } else {
      return null;
    }
  };
  return <>{renderIcon()}</>;
};

const PricingCards = ({
  paidTime,
  pricePerHour,
  currency,
  psHours = false,
  primary = false,
  iconType = false,
  borderColor = false,
  bigScreenStyle = false,
  cardHeaderTitle = false,
}) => {
  const theme = useTheme();
  const cardBackgroundColor = theme.palette.dark.main;

  const sonyCardOutlined = "1px solid #00FFC8";
  const bestSellerCardOutlined = "1px solid #AF85FF";

  return (
    <Card
      sx={{
        overflow: "visible",
        position: "relative",
        width: "208px",
        height: "264px",
        border: borderColor ? sonyCardOutlined : "none",
        backgroundColor: cardBackgroundColor,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        borderRadius: "10px",
      }}
    >
      {cardHeaderTitle ? (
        <CardHeaderStyledTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Typography color="#FFFFFF" variant="smallBoxFont">
              Gratis Sok
            </Typography>
            <img src={drinkIcon} alt="drinkIcon" style={{ top: "10px" }} />
          </Box>
        </CardHeaderStyledTitle>
      ) : null}

      {bigScreenStyle ? (
        <BigScreenStyledBox>
          <Typography variant="smallFontBox">TV 100''</Typography>
        </BigScreenStyledBox>
      ) : null}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography variant="paidTime">{paidTime}</Typography>
        <CardIcon iconType={iconType ? "ps" : "pc"} />
      </Box>
      <Box width="130px" borderTop="1px solid white" />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {psHours ? (
          <Box sx={{ paddingTop: "10px" }}>
            <Typography variant="psHours">1h / </Typography>
          </Box>
        ) : null}
        <Typography variant="pricePerHour">
          {pricePerHour}
          <Typography variant="regnumCurrency">{currency}</Typography>
        </Typography>
      </Box>
    </Card>
  );
};

export default PricingCards;
