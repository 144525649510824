import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import img1 from "../../assests/img/interior/img1.jpg";
import img2 from "../../assests/img/interior/img2.jpg";
import img3 from "../../assests/img/interior/img3.jpg";
import img4 from "../../assests/img/interior/img4.jpg";
import img5 from "../../assests/img/interior/img5.jpg";
import img6 from "../../assests/img/interior/img6.jpg";
import img7 from "../../assests/img/interior/img7.jpg";
import img8 from "../../assests/img/interior/img8.jpg";
import img9 from "../../assests/img/interior/img9.jpg";
import img10 from "../../assests/img/interior/img10.jpg";

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];

const MobileGallery = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ flexGrow: 1, margin: "16px", paddingTop: "2rem" }}>
      <Box display="flex" justifyContent="center" paddingBottom="2rem">
        <Typography variant="mobilePageTitle">Galerija</Typography>
      </Box>

      {images.map((img, index) => (
        <div key={index}>
          {Math.abs(activeStep - index) <= 2 ? (
            <Box
              component="img"
              sx={{
                height: "100%",
                display: "block",
                width: "100%",
                overflow: "hidden",
                width: "100%",
              }}
              src={img}
              alt={`image ${index + 1}`}
            />
          ) : null}
        </div>
      ))}
      <MobileStepper
        sx={{ backgroundColor: "transparent" }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
};

export default MobileGallery;
