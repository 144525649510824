import React from "react";
import { Nav } from "./Nav";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import ScrollToHashElement from "../pages/Components/ScrollToHashElement";
import ScrollToTopButton from "./ScrollToTopButton";
import Tournament from "./advertisement/Tournament";

const RootDiv = styled("div")`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const ContentDiv = styled("div")`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const PageTemplate = () => {
  return (
    <RootDiv>
      <Nav />
      <ContentDiv>
        <Outlet />
      </ContentDiv>
      <ScrollToHashElement />
      {/* <ScrollToTopButton /> */}
    </RootDiv>
  );
};
