import React, { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import tv01 from "../../src/assests/img/interior/tv01.jpg";
import tv02 from "../../src/assests/img/interior/tv02.jpg";
import tv04 from "../../src/assests/img/interior/tv04.jpg";
import tv05 from "../../src/assests/img/interior/tv05.jpg";
import regnumTeam from "../../src/assests/img/interior/regnumTeam.jpg";

export function CustomImageList() {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (selectedImage) => {
    setSelectedImage(selectedImage);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <ImageList
        sx={{ width: "auto", height: "auto" }}
        cols={3}
        rowHeight={"auto"}
      >
        {itemData.map((item) => (
          <ImageListItem key={item.img} onClick={() => handleOpen(item.img)}>
            <img
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>

      <Dialog open={open} onClose={handleClose}>
        {/* <DialogTitle>Full Screen Image</DialogTitle> */}
        <DialogContent>
          {itemData.map((item) => (
            <img
              // src={itemData}
              // alt="Full Screen Image"
              style={{ width: "100%", height: "auto" }}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
}

const itemData = [
  {
    title: "Boot Camp",
    img: regnumTeam,
  },

  {
    title: "2 vs 2",
    img: tv04,
  },
  {
    title: "TV 100 inch",
    img: tv02,
  },
  {
    title: "1 vs 1",
    img: tv01,
  },
  {
    title: "Fifa",
    img: tv05,
  },
];

export default CustomImageList;
