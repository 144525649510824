import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import teamBuilding from "../../../assests/img/usfFon.jpg";

function TeamBuilding() {
  return (
    <Box display="flex" flexDirection="column">
      <Box sx={{ textAlign: "center", mt: 1 }}>
        <Typography variant="mobilePageTitle">Team Building</Typography>
      </Box>
      <Box
        sx={{ opacity: "0.8", padding: "8px" }}
        component="img"
        height="200px"
        src={teamBuilding}
        alt="birthday-cake"
      />
      <Box padding="1rem">
        <Typography variant="birthdayContent">
          Ako tražite jedinstveni način za unapređenje saradnje i timskog rada u
          vašoj firmi, naša igraonica je pravo mesto za to. <br />
          <br />
          Igrajte zajedno, učite jedni od drugih, i stvorite jači timski duh!
          <br />
          <br />
        </Typography>
      </Box>
    </Box>
  );
}

export default TeamBuilding;
