export const handleButtonCallUp = () => {
  window.location.href = `tel:+381628007717`;
};

export const handleButtonEmail = () => {
  window.location.href = `mailto:gaming@regnum.rs`;
};

export const handleButtonGoogleMaps = () => {
  const googleMapsURL = "https://maps.app.goo.gl/1xafKxMjTeJNjE1m7";
  window.open(googleMapsURL, "_blank");
};

export const handleButtonGrappoloShop = () => {
  const grappoloShop = "https://www.grappolo.rs/";
  window.open(grappoloShop, "_blank");
};

export const handleScrollToBirthday = () => {
  const birthdaySection = document.querySelector("#birthday");
  if (birthdaySection) {
    birthdaySection.scrollIntoView({ behavior: "smooth" });
  }
};
