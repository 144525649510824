import React, { useState, useEffect } from "react";
import { Container, Typography, Box, useMediaQuery } from "@mui/material";

import data from "../data/galleryData.json";
import Modal from "../components/Modal";

import img1 from "../assests/img/interior/img1.jpg";
import img2 from "../assests/img/interior/img2.jpg";
import img3 from "../assests/img/interior/img3.jpg";
import img4 from "../assests/img/interior/img4.jpg";
import img5 from "../assests/img/interior/img5.jpg";
import img6 from "../assests/img/interior/img6.jpg";
import img7 from "../assests/img/interior/img7.jpg";
import img8 from "../assests/img/interior/img8.jpg";
import img9 from "../assests/img/interior/img9.jpg";
import img10 from "../assests/img/interior/img10.jpg";

export const Gallery = () => {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");

  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];

  const handleClick = (item, index) => {
    if (!isMobile) {
      setCurrentIndex(index);
      setClickedImg(images[index]);
    }
  };

  const handelRotationRight = () => {
    const totalLength = images.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      setClickedImg(images[0]);
      return;
    }
    const newIndex = currentIndex + 1;
    setClickedImg(images[newIndex]);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = images.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      setClickedImg(images[totalLength - 1]);
      return;
    }
    const newIndex = currentIndex - 1;
    setClickedImg(images[newIndex]);
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        handelRotationRight();
      } else if (event.key === "ArrowLeft") {
        handelRotationLeft();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex, images]);

  return (
    <section>
      <Container maxWidth="xl">
        <Box sx={{ marginTop: "2rem" }}>
          <Typography
            sx={{
              typography: { xs: "pageTitle", md: "pageTitle" },
              paddingBottom: "2rem",
            }}
          >
            Galerija
          </Typography>
          <div className="wrapper">
            {data.data.map((item, index) => (
              <div
                onClick={() => !isMobile && handleClick(item, index)}
                key={index}
                className="wrapper-images"
              >
                <img
                  style={{ borderRadius: "0.5rem" }}
                  src={images[index]}
                  alt={item.text}
                />
                {/* <h2>{item.text}</h2> */}
              </div>
            ))}
            <div>
              {clickedImg && !isMobile && (
                <Modal
                  clickedImg={clickedImg}
                  handelRotationRight={handelRotationRight}
                  setClickedImg={setClickedImg}
                  handelRotationLeft={handelRotationLeft}
                />
              )}
            </div>
          </div>
        </Box>
      </Container>
    </section>
  );
};
