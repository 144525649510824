import styled from "@emotion/styled";
import {
  AppBar,
  Box,
  Button,
  Tab,
  Toolbar,
  Typography,
  Card,
} from "@mui/material";

export const NavbarLinks = styled(Box)(() => ({
  display: "flex",
  gap: "2rem",
}));

export const CustomTab = styled(Tab)((props) => ({
  color: "white",
  fontSize: "18px",
  textTransform: "capitalize",
  fontStyle: "normal",
  fontWeight: "300",
  lineHeight: "22px",
  "&:hover": {
    color: "#00FFC8",
  },
}));

export const ShopButton = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: "1.3rem",
  textTransform: "capitalize",
  width: "9.4rem",
  padding: 0,
}));

export const CallUpButtonStyled = styled(Box)(() => ({
  border: "2px solid #AF85FF",
  height: "2.5rem",
  width: "2.5rem",
  borderRadius: "50%",
  fontSize: "2.5rem",
  zIndex: "2",
  placeItems: "center",
  display: "grid",
  justifyContent: "center",
}));

export const CustomAppbar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#21272A",
  opacity: "0.8",
}));

export const CustomToolbar = styled(Toolbar)((theme) => ({
  width: "100%",
  height: "60px",
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
}));

// Prices custom components

export const PricesTitle = styled(Typography)(() => ({}));

export const OfferCustomCard = styled(Card)(({ theme }) => ({
  width: "25rem",
  height: "510px",
  borderRadius: "10px",
  backgroundColor: "transparent",
  boxShadow: " 0px 2px 10px rgba(175, 133, 255, 0.50); ",

  [theme.breakpoints.down("md")]: {
    // height: "",
    width: "auto",
    height: "auto",
  },
}));
