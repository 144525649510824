import { createTheme } from "@mui/material";

const Colors = {
  primary: "#8565C2",
  secondary: "#00FFC8",
  background: "#1E1E1E",
  gray: "#262B2E",
  inherit: "#00876A",
  white: "#FFFFFF",
  dark: "#1B2123",
  purple: "#AF85FF",
  neonGreen: "#00ffc8",
};

const identical = {
  color: " #FFF",
  textAlign: "center",
  fontFamily: "Figtree",
  fontStyle: "normal",
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
    background: {
      main: Colors.background,
    },
    white: {
      main: Colors.white,
    },
    dark: {
      main: Colors.dark,
    },
    purple: {
      main: Colors.purple,
    },
    neonGreen: {
      main: Colors.neonGreen,
    },

    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  },

  typography: {
    fontFamily: ["Figtree", "sans-serif"].join(","),
    htmlFontSize: 16,
    fontSize: 14,
    fontWeightLight: "300",
    fontWeighRegular: "400",
    fontWeightMedium: "500",
    fontWeightBold: "700",

    pageTitle: {
      color: "#FFF",
      // textShadow: " 0px 4px 12px #AF85FF",
      fontFamily: "Figtree",
      fontSize: "2.5rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "160.4%" /* 128.32px */,
      letterSpacing: " -0.8px",
      textTransform: "capitalize",
    },
    mobilePageTitle: {
      color: "#FFF",
      textShadow: " 0px 2px 6px #AF85FF",
      fontFamily: "Figtree",
      fontSize: "2rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "160.4%" /* 128.32px */,
      letterSpacing: " -0.8px",
      textTransform: "capitalize",
    },
    pageSubtitle: {
      fontFamily: "Figtree",
      fontSize: "2rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "160.4%" /* 128.32px */,
      letterSpacing: " -0.8px",
      textTransform: "capitalize",
    },
    contactPageTitle: {
      fontFamily: "Figtree",
      color: "#00FFC8",
      fontSize: "2.5rem",
      fontStyle: "normal",
      fontWeight: "700",
      // lineHeight: "160.4%" /* 128.32px */,
      letterSpacing: " -0.8px",
      textTransform: "capitalize",
    },
    contactPageSubtitle: {
      fontFamily: "Figtree",
      fontSize: "1.2rem",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "160.4%" /* 128.32px */,
      letterSpacing: " 3.4px",
      textTransform: "capitalize",
    },
    mobilePageSubtitle: {
      fontFamily: "Figtree",
      fontSize: "2rem",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "160.4%" /* 128.32px */,
      letterSpacing: " -0.8px",
      textTransform: "capitalize",
    },
    iconTitle: {
      ...identical,
      fontSize: "1.6rem",
      fontWeight: "600",
      lineheight: "136.9%" /* 54.76px */,
    },
    paidTime: {
      ...identical,
      fontSize: "8rem",
      fontWeight: "700",
      lineHeight: "136.9%" /* 131.424px */,
    },
    pricePerHour: {
      ...identical,
      fontSize: "3rem",
      fontWeight: "600",
      lineHeight: "136.9%" /* 131.424px */,
    },
    regnumCurrency: {
      ...identical,
      fontSize: "1rem",
      fontWeight: "600",
      lineHeight: "136.9%" /* 131.424px */,
    },
    psHours: {
      ...identical,
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "136.9%" /* 21.904px */,
    },
    smallBoxFont: {
      color: "#1B2123",
      textAlign: "center",
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "136.9%" /* 131.424px */,
    },
    footerFont: {
      ...identical,
      color: "#fff",
      textAlign: "left",
      fontSize: "1.2rem",
      fontWeight: "600",
    },
    footerCopyright: {
      ...identical,
      color: "#FFF",
      textAlign: "center",
      fontSize: "1rem",
      fontWeight: "400",
    },
    boxText: {
      ...identical,
      color: "#FFF",
      textAlign: "center",
      fontSize: "1.5rem",
    },
    dialogTitle: {
      ...identical,
      color: "#AF85FF",
      textAlign: "center",
      fontSize: "2.5rem",
      textShadow: " 0px 2px 8px #AF85FF",
      fontFamily: "Figtree",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "160.4%" /* 128.32px */,
      letterSpacing: " -0.8px",
      // textTransform: "capitalize",
    },
    dialogText: {
      ...identical,
      color: "#FFF",
      textAlign: "center",
      fontSize: "1.3rem",
    },
    birthdayContent: {
      ...identical,
      color: "#FFF",
      textAlign: "center",
      fontSize: "1.2rem",
    },
    advertisementTitle: {
      fontFamily: "Figtree",
      fontStyle: "italic",
      color: "#fff",
      textAlign: "center",
      fontSize: "4.5rem",
      fontWeight: "700",
      // textShadow: "2px 2px 2px #21272A",
    },
    advertisementTitleMob: {
      fontFamily: "Figtree",
      color: "#fff",
      textAlign: "center",
      fontStyle: "italic",
      fontSize: "2.5rem",
      fontWeight: "700",
      textShadow: "2px 2px 2px #21272A",
    },
    advertisementContent: {
      fontFamily: "Figtree",
      color: "#00FFC8",
      fontStyle: "italic",
      textAlign: "center",
      fontSize: "3.5rem",
      fontWeight: "700",
      textShadow: "2px 2px 2px #21272A",
    },
    advertisementContentMob: {
      fontFamily: "Figtree",
      color: "#FFF",
      textAlign: "center",
      fontStyle: "italic",
      fontSize: "1.5rem",
      fontWeight: "700",
      textShadow: "2px 2px 2px #21272A",
    },
    advertisementButton: {
      fontFamily: "Figtree",
      color: "#FFF",
      textAlign: "center",
      fontSize: "1.5rem",
      fontWeight: "00",
    },
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
    },
  },
});

export default theme;
