import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import playStation from "../../../assests/img/interior/sonyPlaystationWeb.jpg";

function PlayStation() {
  return (
    <Box display="flex" flexDirection="column">
      <Box sx={{ textAlign: "center", mt: 1 }}>
        <Typography variant="mobilePageTitle">PlayStation®5</Typography>
      </Box>
      <Box
        sx={{ opacity: "0.8", padding: "8px" }}
        component="img"
        height="200px"
        src={playStation}
        alt="birthday-cake"
      />
      <Box padding="1rem">
        <Typography variant="birthdayContent">
          Ako tražite jedinstveni način za unapređenje saradnje i timskog rada u
          vašoj firmi, naša igraonica je pravo mesto za to. <br />
          <br />
          Igrajte zajedno, učite jedni od drugih, i stvorite jači timski duh!
          <br />
          <br />
        </Typography>
      </Box>
    </Box>
  );
}

export default PlayStation;
