import { useMediaQuery } from "@mui/material";
import React from "react";

import { useTheme } from "@emotion/react";
import { AppbarDesktop } from "./appbar/AppbarDesktop";
import { AppbarMobile } from "./appbar/AppbarMobile";

export const Nav = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return <>{matches ? <AppbarMobile /> : <AppbarDesktop />}</>;
};
