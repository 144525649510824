import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import bootCamp from "../../../assests/img/interior/img2.jpg";

function BootCamp() {
  return (
    <Box display="flex" flexDirection="column">
      <Box sx={{ textAlign: "center", mt: 1 }}>
        <Typography variant="mobilePageTitle">Boot Camp</Typography>
      </Box>
      <Box
        sx={{ opacity: "0.8", padding: "8px" }}
        component="img"
        height="200px"
        src={bootCamp}
        alt="birthday-cake"
      />
      <Box padding="1rem">
        <Typography variant="birthdayContent">
          Boot Camp je idealno mesto za sve one koji žele da unaprede svoje
          gejming veštine.
          <br />
          <br />
          Specijalno dizajnirani trening centar za gejmere svih nivoa.
          <br />
          <br />
        </Typography>
      </Box>
    </Box>
  );
}

export default BootCamp;
