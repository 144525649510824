import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  List,
  ListItem,
  ThemeProvider,
  Typography,
} from "@mui/material";
import pcConfig from "../assests/img/pcConfig.png";
import styled from "@emotion/styled";
import theme from "../styles/theme";
import AlertDialog from "../styles/ui/AlertDialog";

const Background = styled("div")({
  width: "100%",
  height: "100%",
  flexGrow: "1",
  backgroundImage: `url(${pcConfig})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
});

export const Config = () => {
  const [open, setOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <Background>
        <Container
          maxWidth="xl"
          sx={{
            paddingTop: { xs: "1rem", md: "3rem" },
            paddingBottom: { xs: "1rem", md: "3rem" },
          }}
        >
          <Box
            sx={{
              padding: 0,
              textAlign: { xs: "center", md: "left" },
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              sx={{
                typography: { xs: "mobilePageTitle", md: "pageTitle" },
                paddingBottom: "2rem",
              }}
            >
              PC konfiguracije
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-around",
              paddingTop: "2rem",
            }}
          >
            <Box>
              <Card
                elevation={6}
                sx={{
                  background: "rgba(0, 0, 1, 0.55)",
                  borderRadius: "16px",
                  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1, 0.2)",
                  backdropFilter: "blur(6.4px)",
                  backgroundColor: "#1B2123",
                  width: { xs: "auto", md: "auto" },
                  height: "auto",
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      padding: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        typography: {
                          xs: "pageSubtitle",
                          md: "pageSubtitle",
                          color: theme.palette.secondary.main,
                        },
                      }}
                    >
                      Regular
                    </Typography>
                  </Box>
                  <CardHeader
                    sx={{ color: theme.palette.white.main }}
                    title="Standardna igraonica"
                  />
                </Box>
                <Box sx={{ padding: "16px" }}>
                  <Box
                    sx={{
                      borderTop: "1px solid #AF85FF",
                      width: "100%",
                    }}
                  ></Box>
                </Box>

                <CardContent>
                  <List
                    sx={{
                      listStyleType: "disc",
                      color: theme.palette.white.main,
                      pl: 2,
                    }}
                  >
                    <ListItem sx={{ display: "list-item" }}>
                      Ryzen 5 5600X
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      G.Skill Trident Z16GB
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      256GB Nvme Samsung
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      Gigabyte RTX 1660 Super
                    </ListItem>{" "}
                    <ListItem sx={{ display: "list-item" }}>
                      Gigabyte 24” 175 Hz
                    </ListItem>{" "}
                    <ListItem sx={{ display: "list-item" }}>
                      Optical internet MTS 1000Mb/100Mb
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Box>
            <Box>
              <Card
                elevation={6}
                sx={{
                  backgroundColor: "#1B2123",
                  width: { xs: "auto", md: "auto" },
                  height: "auto",
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="pageSubtitle"
                    sx={{ color: theme.palette.purple.main, padding: "15px" }}
                  >
                    Boot Camp
                  </Typography>
                  <CardHeader
                    sx={{ color: theme.palette.white.main }}
                    title="Potpuno izolovana soba"
                  />
                </Box>
                <Box sx={{ padding: "16px" }}>
                  <Box
                    sx={{
                      borderTop: "1px solid #AF85FF",
                      width: "100%",
                    }}
                  ></Box>
                </Box>

                <CardContent>
                  <List
                    sx={{
                      listStyleType: "disc",
                      color: theme.palette.white.main,
                      pl: 2,
                    }}
                  >
                    <ListItem sx={{ display: "list-item" }}>
                      Ryzen 7 5800X
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      G.Skill Trident Z32GB
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      512GB Nvme Aorus
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      Gigabyte RTX 3060
                    </ListItem>{" "}
                    <ListItem sx={{ display: "list-item" }}>
                      ASUS 25" XG258Q Gaming 240Hz
                    </ListItem>{" "}
                    <ListItem sx={{ display: "list-item" }}>
                      Optical internet MTS 1000Mb/100Mb
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Container>
      </Background>
    </ThemeProvider>
  );
};
