import styled from "@emotion/styled";
import { Button, Container, Typography } from "@mui/material";

export const HeroImage = styled.div(({ theme }) => ({
  height: "100vh",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "relative",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  webkitTransform: "scaleX(-1)",
  transform: "scaleX(-1)",
  [theme.breakpoints.down("md")]: {
    height: "",
    width: "",
    webkitTransform: "none",
    transform: "none",
  },
}));

export const HeaderContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "absolute",
  left: "15.05%",
  right: " 36.05%",
  top: " 30%",
  [theme.breakpoints.down("md")]: {
    top: "30%",
    padding: 0,
    left: "16px",
  },
}));

export const HeroText = styled(Typography)(({ theme }) => ({
  fontWeight: "700",
  fontSize: "7.5rem",
  lineHeight: "95%",
  letterSpacing: "-0.01em",
  textTransform: "uppercase",
  textShadow: "0px 4px 24px rgba(175, 133, 255, 0.5)",
  [theme.breakpoints.down("900")]: {
    fontSize: "5.625rem",
    textShadow: "0px 4px 24px #AF85FF",
  },
  [theme.breakpoints.down("600")]: {
    fontSize: "2.5rem",
    textShadow: "0px 4px 24px #AF85FF",
  },
}));

export const HeroInfo = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "1.5rem",
  lineHeight: "160%",
  textTransform: "capitalize",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.125rem",
    opacity: "0.7",
  },
}));

export const HeroButton = styled(Button)(({ theme }) => ({
  //   position: "absolute",
  width: "374px",
  height: "66.24px",
  textTransform: "capitalize",
  fontWeight: "500",
  fontSize: "24px",
  lineHeight: "29px",
  padding: 0,
  boxShadow: "0 0 0,125em hsl(0 0% 100% / 0.5), 0 0 0.45em",

  [theme.breakpoints.down("900")]: {
    fontSize: "18px",
    fontWeight: "500",
    maxWidth: "328px",
    width: " 270px",
    height: "50px",
    padding: 0,
  },
  [theme.breakpoints.down("600")]: {
    fontSize: "18px",
    fontWeight: "500",
    maxWidth: "328px",
    width: " 200px",
    height: "50px",
    padding: 0,
  },
}));
