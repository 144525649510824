import React from "react";
import { Container, Typography, Box } from "@mui/material";
import BirthdayCard from "../components/BirthdayCard";

const smallOfferDescription = [
  "3 sata na standard računarima",
  "3 sata igranja na PS5",
  "10 sendviča",
  "jedno piće po želji",
];

const mediumOfferDescription = [
  "3 sata na standard računarima",
  "3 sata igranja na PS5",
  "15 sendviča",
  "jedno piće po želji",
];

const largeOfferDescription = [
  "3 sata na standard računarima",
  "3 sata igranja na PS5",
  "20 sendviča",
  "jedno piće po želji",
];

const Birthday = () => {
  return (
    <section>
      <div>
        <Container
          maxWidth="xl"
          sx={{
            marginTop: "1.5rem",
            paddingTop: "1.5rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
            <Typography
              sx={{
                typography: {
                  xs: "mobilePageTitle",
                  md: "pageTitle",
                },
              }}
            >
              Proslava Rođendana
            </Typography>
            <Box
              sx={{
                display: "flex",
                paddingTop: "3rem",
                paddingBottom: "3rem",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                gap: "2rem",
              }}
            >
              <BirthdayCard
                name="MALI PAKET"
                players="(do 10 gejmera)"
                price="15.000 Dinara"
                descriptions={smallOfferDescription}
                buttonVariant={"outlined"}
              />
              <BirthdayCard
                name="SREDNJI PAKET"
                players="(do 15 gejmera)"
                price="22.500 Dinara"
                descriptions={mediumOfferDescription}
                buttonVariant={"contained"}
                borderColor={true}
              />
              <BirthdayCard
                name="VELIKI PAKET"
                players="(do 20 gejmera)"
                price="30.000 Dinara"
                descriptions={largeOfferDescription}
                buttonVariant={"outlined"}
              />
            </Box>
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="regnumCurrency"
                sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}
              >
                *za više od dvadesetoro dece se naplaćuje dodadtnih 1000 dinara
                po detetu
              </Typography>
            </Box>
          </Box>
        </Container>
      </div>
    </section>
  );
};

export default Birthday;
