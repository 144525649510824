import React from "react";
import { CustomAppbar } from "../../styles/appbar";
import {
  Button,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Box,
} from "@mui/material";
import miniLogo from "../../assests/img/logoCroped.png";
import MenuIcon from "@mui/icons-material/Menu";
import { styled, useTheme } from "@mui/material/styles";
import { MuiAppBar } from "@mui/material/AppBar";
import { Link, Link as RouterLink } from "react-router-dom";
import CallUpButton from "../../styles/ui/CallUpButton";

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)`
  width: ${drawerWidth}px;
  flex-shrink: 0;

  & .MuiDrawer-paper {
    width: ${drawerWidth}px;
    background-color: transparent;
  }
  & .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    height: auto;
  }
`;

const StyledButton = styled(Button)`
  margin: 16px;
`;

const StyledList = styled(List)`
  padding-top: 0;
  align-text: center;
  background-color: #21272a;
  border-radius: 0.5rem;
`;

const StyledListItemText = styled(ListItemText)`
  color: white;
  & .css-1r8ixna-MuiTypography-root {
    font-size: 1.5rem;
  }
`;

export const AppbarMobile = ({ matches }) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const grappoloShop = "https://www.grappolo.rs/";

  const handleButtonGrappoloShop = () => {
    window.open(grappoloShop, "_blank");
  };

  return (
    <>
      <CustomAppbar position="sticky">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link component={RouterLink} to="/#home">
            <img src={miniLogo} width={120} alt="logo" />
          </Link>
          <StyledDrawer
            anchor="right"
            open={open}
            onClose={handleDrawerClose}
            sx={{ flexDirection: "flex-end" }}
          >
            <div style={{ height: 64 }} />
            <StyledList>
              <ListItemButton to="/#home" onClick={handleDrawerClose}>
                <StyledListItemText primary="Početna" />
              </ListItemButton>
              <ListItemButton to="/#birthday" onClick={handleDrawerClose}>
                <StyledListItemText primary="Proslave" />
              </ListItemButton>
              <ListItemButton to="/prices">
                <StyledListItemText primary="Cene" />
              </ListItemButton>
              {/* <ListItemButton to="/config">
                <StyledListItemText primary="Konfiguracija" />
              </ListItemButton> */}
              <ListItemButton to="/gallery">
                <StyledListItemText primary="Galerija" />
              </ListItemButton>
              <ListItemButton to="/contact">
                <StyledListItemText primary="Kontakt" />
              </ListItemButton>
              <ListItemButton onClick={handleButtonGrappoloShop}>
                <StyledListItemText primary="Shop" />
              </ListItemButton>
            </StyledList>
          </StyledDrawer>
          <Box sx={{ display: "flex" }}>
            <IconButton
              variant="contained"
              color="secondary"
              onClick={handleDrawerOpen}
              sx={{ padding: 0, marginLeft: "1.5rem" }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Box>
        </Toolbar>
      </CustomAppbar>
    </>
  );
};
