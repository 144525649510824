import { Box, Grid, Container, Typography } from "@mui/material";
import React from "react";
import miniLogo from "../assests/img/LogoR.svg";
import { ContainerWithImageBg } from "../components/ContainerWithImageBg";
import PCicon from "../assests/img/PCicon.svg";
import PSicon from "../assests/img/PSicon.svg";

import PricingCards from "./Components/PricingCards";

export const Prices = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        paddingTop: 3,
        display: "flex",
        flexDirection: "column",
        paddingBottom: 3,
      }}
    >
      <ContainerWithImageBg image={miniLogo}>
        <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
          <Typography
            sx={{ typography: { xs: "mobilePageTitle", md: "pageTitle" } }}
          >
            Cenovnik
          </Typography>
        </Box>
        <Box sx={{ pt: { xs: "1rem", md: "3rem" } }}>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignContent: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img src={PCicon} alt="PCicon" />
              <Typography variant="iconTitle">PC</Typography>
            </Box>
            <Box flexGrow={1} sx={{ paddingLeft: { md: 10, xs: 9 } }}>
              <Grid container spacing={12}>
                <Grid item sm={3}>
                  <Box
                    sx={{
                      display: { xs: "block", md: "none" },
                      paddingBottom: "10px",
                    }}
                  >
                    <Typography variant="iconTitle">PC</Typography>
                    <Box paddingTop="10px">
                      <Box
                        sx={{
                          borderTop: "1px solid #AF85FF",
                          width: "130px",
                        }}
                      ></Box>
                    </Box>
                  </Box>
                  <PricingCards
                    paidTime={1}
                    currency="RSD"
                    pricePerHour={200}
                    sonyPlayers="h"
                    primary
                  />
                </Grid>
                <Grid item sm={3}>
                  <PricingCards
                    currency="RSD"
                    paidTime={5}
                    cardHeaderTitle
                    pricePerHour={800}
                    sonyPlayers="h"
                  />
                </Grid>
                <Grid item sm={3}>
                  <PricingCards
                    currency="RSD"
                    cardHeaderTitle
                    paidTime={10}
                    pricePerHour={1400}
                    sonyPlayers="h"
                    primary
                  />
                </Grid>
                <Grid item sm={3}>
                  <PricingCards
                    currency="RSD"
                    paidTime={15}
                    cardHeaderTitle
                    pricePerHour={1950}
                    sonyPlayers="h"
                    primary
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ display: "flex", mt: 12 }}>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignContent: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img src={PSicon} alt="PSicon" />
              <Typography variant="iconTitle">PS</Typography>
            </Box>
            <Box flexGrow={1} sx={{ paddingLeft: 10 }}>
              <Box
                sx={{
                  display: { xs: "block", md: "none" },
                  paddingBottom: "10px",
                }}
              >
                <Typography variant="iconTitle">PlayStation 5</Typography>
                <Box paddingTop="10px">
                  <Box
                    sx={{
                      borderTop: "1px solid #AF85FF",
                      width: "130px",
                    }}
                  ></Box>
                </Box>
              </Box>
              <Grid container spacing={12}>
                <Grid item sm={3}>
                  <PricingCards
                    currency="RSD"
                    paidTime={2}
                    pricePerHour={250}
                    sonyPlayers={"h"}
                    primary
                    iconType
                    psHours
                  />
                </Grid>
                <Grid item sm={3}>
                  <PricingCards
                    currency="RSD"
                    paidTime={4}
                    sonyPlayers={"h"}
                    pricePerHour={300}
                    primary
                    iconType
                    psHours
                  />
                </Grid>
                <Grid item sm={3}>
                  <PricingCards
                    style={{ border: "1px solid white" }}
                    currency="RSD"
                    paidTime={2}
                    sonyPlayers={"h"}
                    pricePerHour={350}
                    primary
                    iconType
                    borderColor
                    bigScreenStyle
                    psHours
                  />
                </Grid>
                <Grid item sm={3}>
                  <PricingCards
                    currency="RSD"
                    paidTime={4}
                    borderColor
                    sonyPlayers={"h"}
                    pricePerHour={400}
                    primary
                    iconType
                    bigScreenStyle
                    psHours
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </ContainerWithImageBg>
    </Container>
  );
};
