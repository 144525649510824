import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Home } from "./pages/Home";
import { Contact } from "./pages/Contact";
import { PageTemplate } from "./components/PageTemplate";
import theme from "./styles/theme";
import { ThemeProvider } from "@mui/material";
import { Prices } from "./pages/Prices";
import { Config } from "./pages/Config";
import { Gallery } from "./pages/Gallery";

import { Registration } from "./pages/Registration";
import "./App.css";
import OfferPage from "./pages/OfferPage";
import CallUpButton from "./styles/ui/CallUpButton";
import About from "./pages/About";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageTemplate />,
    children: [
      {
        path: "/gallery",
        element: <Gallery />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/config",
        element: <Config />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/prices",
        element: <Prices />,
      },
      {
        path: "/registration",
        element: <Registration />,
      },

      { path: "/", element: <Home /> },
      { path: "/offer", element: <OfferPage /> },
    ],
  },
]);

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <CallUpButton />
      {/* <ScrollToTopButton /> */}
    </ThemeProvider>
  );
};
