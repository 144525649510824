import React, { useRef } from "react";
import {
  CustomTab,
  CustomAppbar,
  NavbarLinks,
  CustomToolbar,
  ShopButton,
} from "../../styles/appbar";
import { Box, Container, Tabs } from "@mui/material";
import { Link, Link as RouterLink } from "react-router-dom";
import logoCroped from "../../assests/img/logoCroped.png";
import { ShoppingCart } from "@mui/icons-material";

import { handleButtonGrappoloShop } from "../../constants";

export const AppbarDesktop = () => {
  return (
    <Container maxWidth="xl">
      <div style={{ paddingTop: "4rem" }}>
        <CustomAppbar elevation={0}>
          <CustomToolbar disableGutters={true}>
            <Box component="div" class="logo">
              <Link component={RouterLink} to="/#home">
                <img
                  src={logoCroped}
                  alt="logo"
                  width={150}
                  sx={{ display: "flex" }}
                />
              </Link>
            </Box>
            <Box component="div" class="links">
              <NavbarLinks component="div">
                <Tabs>
                  <CustomTab
                    label="Početna"
                    component={RouterLink}
                    to="/#home"
                  />
                  <CustomTab
                    label="Proslave"
                    component={RouterLink}
                    to="/#birthday"
                  />
                  <CustomTab
                    label="Cene"
                    component={RouterLink}
                    to="/#prices"
                  />
                  {/* <CustomTab
                    label="Konfiguracija"
                    component={RouterLink}
                    to="/config"
                  /> */}
                  <CustomTab
                    label="Galerija"
                    component={RouterLink}
                    to="/#gallery"
                  />
                  <CustomTab
                    label="Kontakt"
                    component={RouterLink}
                    to="/#contact"
                  />
                </Tabs>
              </NavbarLinks>
            </Box>
            <Box class="actionButton">
              <ShopButton
                variant="outlined"
                endIcon={<ShoppingCart />}
                onClick={handleButtonGrappoloShop}
              >
                Shop
              </ShopButton>
            </Box>
          </CustomToolbar>
        </CustomAppbar>
      </div>
    </Container>
  );
};
