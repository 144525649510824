import {
  Container,
  Typography,
  useMediaQuery,
  Box,
  Button,
} from "@mui/material";
import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import heroImage from "../assests/img/heroImage.png";

import { css, useTheme } from "@emotion/react";
import { HeroText, HeroInfo } from "./HomeStyle";
import AboutUs from "./AboutUs";
import { Config } from "./Config";
import OfferPage from "./OfferPage";
import { Prices } from "./Prices";
import { Gallery } from "./Gallery";
import { Contact } from "./Contact";
import Birthday from "./Birthday";
import { handleButtonCallUp } from "../constants";
import MobileGallery from "./Components/MobileGallery";
import { useNavigate } from "react-router-dom";
import CustomImageList from "../components/CustomImageList";

const SmallHeroText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.6);

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.values.md}px) {
      margin: 0 5;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
    }
  `}
`;

export const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const sectionsRef = useRef([]);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  useEffect(() => {
    const observerCallback = debounce((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const id = entry.target.id;
          navigate(`#${id}`);
        }
      });
    }, 100); // Adjust the debounce delay as needed

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.5, // Adjust this threshold as needed
    });

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, [navigate]);

  const heroImageBackground = {
    backgroundImage: `url(${heroImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    minWidth: "100%",
    minHeight: "100vh",
    // backgroundPosition: "center",
  };

  return (
    <>
      <div style={{ ...heroImageBackground }}>
        <div id="home" ref={(el) => (sectionsRef.current[0] = el)}>
          <div>
            <Container maxWidth="xl">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "100vh",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "50%",
                      paddingBottom: "1rem",
                    }}
                  >
                    <HeroText
                      className="heroText"
                      id="heroText"
                      data-text="Palace of"
                    >
                      Palace of gamelords
                    </HeroText>
                  </Box>
                  <Box sx={{ paddingBottom: "1rem" }}>
                    <HeroInfo className="heroInfoText">
                      Boot Camp/Gaming Arena/PS5/Gaming Shop/Esports bar
                    </HeroInfo>
                  </Box>
                  <div>
                    {/* <HeroActionContainer> */}
                    <Box mt={2} displ>
                      <Button
                        size="large"
                        href="#birthday"
                        variant="contained"
                        sx={{
                          textTransform: "capitalize",
                          pr: 2,
                          mr: 2,
                          width: { xs: "100%", md: "220px" },
                        }}
                      >
                        Proslave
                      </Button>
                      <Button
                        size="large"
                        variant="outlined"
                        onClick={handleButtonCallUp}
                        sx={{
                          textTransform: "capitalize",
                          pr: 2,
                          mr: 2,
                          mt: isMobile ? 2 : 0,
                          width: { xs: "100%", md: "220px" },
                        }}
                      >
                        Rezerviši
                      </Button>
                      <SmallHeroText sx={{ mt: isMobile ? 1 : 2 }}>
                        Možete otkazati rezervaciju
                      </SmallHeroText>
                    </Box>
                  </div>
                </Box>
              </Box>
            </Container>
          </div>
        </div>
      </div>

      <div id="aboutUs" style={{ paddingTop: "2rem" }}>
        <AboutUs />
      </div>
      <div id="birthday" ref={(el) => (sectionsRef.current[1] = el)}>
        <Birthday />
      </div>
      <div id="offer">
        <OfferPage />
      </div>
      <div id="config">
        <Config />
      </div>
      <div id="prices" ref={(el) => (sectionsRef.current[2] = el)}>
        <Prices />
      </div>
      <div id="gallery" ref={(el) => (sectionsRef.current[3] = el)}>
        {!isMobile && <Gallery />}
      </div>
      <div id="contact" ref={(el) => (sectionsRef.current[4] = el)}>
        <Contact />
      </div>
    </>
  );
};
