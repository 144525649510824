import * as React from "react";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box, Container, Paper } from "@mui/material";
import doorIcon from "../assests/icons/doorIcon.svg";
import shieldIcon from "../assests/icons/shieldIcon.svg";
import bookIcon from "../assests/icons/bookIcon.svg";
import torchIcon from "../assests/icons/torchIcon.svg";

function About() {
  return (
    <Container
      maxWidth="xl"
      sx={{ pt: 2, mt: 2, display: "flex", flexDirection: "column" }}
    >
      <Typography
        sx={{ pb: 2, mb: 2, textAlign: { xs: "center", md: "left" } }}
        variant="dialogTitle"
      >
        istorija
      </Typography>
      <Box
        sx={{
          mt: { xs: "rem", md: 2 },
          pt: { xs: "rem", md: 2 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Paper
          sx={{
            display: "flex",
            margin: { xs: "0.2rem", md: "1rem" },
            padding: "1rem",
            flexDirection: "column",
            border: "1px solid #00FFC8",
            width: { xs: "auto", md: "600px" },
            backgroundColor: "#262B2E",
            borderRadius: "1rem",
          }}
        >
          <Box
            display="flex"
            sx={{
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: "2rem", md: "0" },
            }}
            alignItems="center"
          >
            <Box paddingRight="10px">
              <img src={doorIcon} style={{ width: "5rem" }} alt="door-icon" />
            </Box>
            <Box sx={{ textWrap: { xs: "inherit" } }}>
              <Typography variant="dialogText">
                Regnum Gaming Facility se nalazi u bivšoj kasarni kralja Petra
                Prvog koja je renovirana i prilagođena savremenim potrebama
                gaming zajednice što naš centar čini jedinstvenim i jos
                zanimljivijim.
              </Typography>
            </Box>
          </Box>
          <Box
            paddingTop="2rem"
            display="flex"
            sx={{
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: "2rem", md: "0" },
            }}
            alignItems="center"
          >
            <Box paddingRight="10px">
              <img src={bookIcon} style={{ width: "5rem" }} alt="book-icon" />
            </Box>
            <Box>
              <Typography variant="dialogText">
                Ovaj prostor smo pažljivo osmislili da biste uživali u
                maksimalnom gaming iskustvu, a ujedno osetili duh “stare
                kasarne” ili nove gaming kasarne Kraljevine. Regnum na latinskom
                znači Kraljevina.
              </Typography>
            </Box>
          </Box>
          <Box
            paddingTop="2rem"
            display="flex"
            sx={{
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: "2rem", md: "0" },
            }}
            alignItems="center"
          >
            <Box paddingRight="10px">
              <img src={torchIcon} style={{ width: "5rem" }} alt="torch-icon" />
            </Box>
            <Box>
              <Typography variant="dialogText">
                Igraonica je opremljena najmodernijom opremom, od računara i
                konzola do audio-vizuelne opreme, što osigurava da vaše iskustvo
                bude vrhunsko.
              </Typography>
            </Box>
          </Box>
          <Box
            paddingTop="2rem"
            display="flex"
            sx={{
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: "2rem", md: "0" },
            }}
            alignItems="center"
          >
            <Box paddingRight="10px">
              <img
                src={shieldIcon}
                style={{ width: "5rem" }}
                alt="shield-icon"
              />
            </Box>
            <Box>
              <Typography variant="dialogText">
                Naša oprema se redovno održava i ažurira kako bi se osigurala
                najbolja moguća iskustva za igrače.
              </Typography>
            </Box>
          </Box>
        </Paper>
        {/* <Paper
          sx={{
            display: "flex",
            margin: "1rem",
            padding: "1rem",
            flexDirection: "column",
            border: "1px solid #00FFC8",
            width: "600px",
            backgroundColor: "#262B2E",
            borderRadius: "1rem",
          }}
        ></Paper> */}
      </Box>
    </Container>
  );
}

export default About;
