import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import gameNight from "../../../assests/img/gameNight.png";

function BoardGames() {
  return (
    <Box display="flex" flexDirection="column">
      <Box sx={{ textAlign: "center", padding: "8px" }}>
        <Typography variant="mobilePageTitle">Board Games</Typography>
      </Box>
      <Box
        sx={{ opacity: "0.8", padding: "8px" }}
        component="img"
        height="200px"
        src={gameNight}
        alt="birthday-cake"
      />
      <Box padding="1rem">
        <Typography variant="birthdayContent">
          Možete uživati u čarima društvenih igara u našem posebnom kutku!
          <br />
          <br />
          Okupite ekipu za partiju društvene igre koju volite da igrate.
          <br />
          <br />
          Možete rezervisati vaše mesto na ovom&nbsp;
        </Typography>
      </Box>
    </Box>
  );
}

export default BoardGames;
