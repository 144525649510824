import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import gamingArena from "../../../assests/img/interior/gamingArenaWeb.jpg";

function GamingArena() {
  return (
    <Box display="flex" flexDirection="column">
      <Box sx={{ textAlign: "center", mt: 1 }}>
        <Typography variant="mobilePageTitle">Gaming Arena</Typography>
      </Box>
      <Box
        sx={{ opacity: "0.8", padding: "8px" }}
        component="img"
        height="200px"
        src={gamingArena}
        alt="gaming-arena"
      />
      <Box padding="1rem">
        <Typography variant="birthdayContent">
          Ovde možete da organizujete turnire i takmičenja sa svojim
          prijateljima i drugim igračima.
          <br />
          <br />
          Uživaćete u najnovijim igricama i najboljoj gejming opremi.
          <br />
          <br />
        </Typography>
      </Box>
    </Box>
  );
}

export default GamingArena;
